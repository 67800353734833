import { Buffer } from "buffer";
import { defineStore } from "pinia";
import { getBatchStartEnd } from "~/shared/utils";
import { GetOverrides } from "~/types/AnyCreativeReport";
import { ClientLayout, ClientTargetMetric } from "~/types/Client";
import {
  AdCampaignInfo,
  AdGroupInfo,
  AdInfo,
  AssetInfo,
  Creative,
  CreativeInfo,
  CreativeInfoResponse,
  CreativeResponse,
} from "~/types/Creative";
import {
  CreateViewDefaults,
  CreativeReportingGroup,
  CreativeReportingGroupResponse,
  CreativeReportingReport,
  CreativeReportingReportResponse,
  CreativeReportingView,
  CreativeReportingViewResponse,
  GetGroupDto,
} from "~/types/CreativeReporting";
import { CustomMetricRule, MetricsFilter } from "~/types/Metrics";
import { ReportSnapshotResponse } from "~/types/ReportSnapshot";
import {
  AttributionWindow,
  GroupBy,
  Provider,
  Sort,
  SortBy,
  Timeframe,
  ViewType,
} from "~/types/shared";
import { AdGroupAdTag } from "~/types/Tagging";

export const useCreativeReportingStore = defineStore({
  id: "creative-reporting-store",
  state: () => {
    return {
      ads: [],
      creatives: {},
      assets: [],
      reports: [],
      creativeInfos: [],
      customConversionNames: {},
      customMetricRules: [],
      colorCodingLayout: ClientLayout.RELATIVE,
      targetMetrics: [],
      currency: "EUR",
      creativeIdToCampaignInfo: {},
      creativeIdToAdGroupInfo: {},
    } as {
      ads: Array<AdInfo>;
      creatives: {
        [clientId: number]: { creatives: Array<Creative>; total: number };
      };
      assets: Array<AssetInfo>;
      reports: CreativeReportingReport[];
      creativeInfos: Array<CreativeInfo>;
      customConversionNames: Record<string, string>;
      customMetricRules: CustomMetricRule[];
      colorCodingLayout: ClientLayout;
      targetMetrics: ClientTargetMetric[];
      currency: string;
      creativeIdToCampaignInfo: Record<number, AdCampaignInfo>;
      creativeIdToAdGroupInfo: Record<number, AdGroupInfo>;
    };
  },
  actions: {
    async createReport(input: {
      title: string | null;
      clientId: number;
      folderId: number | null;
      description: string | undefined | null;
      meta: boolean;
      tiktok: boolean;
      youtube: boolean;
      metaDefaults?: Partial<CreateViewDefaults>;
      tiktokDefaults?: Partial<CreateViewDefaults>;
      youtubeDefaults?: Partial<CreateViewDefaults>;
      timeframe?: Timeframe | undefined | null;
      startDate?: string | undefined | null;
      endDate?: string | undefined | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { report: CreativeReportingReportResponse };
      }>("/creative-reporting/create", {
        method: "POST",
        body: {
          title: input.title === "" ? null : input.title,
          description: input.description === "" ? null : input.description,
          clientId: input.clientId,
          meta: input.meta,
          tiktok: input.tiktok,
          folderId: input.folderId,
          youtube: input.youtube,
          metaDefaults: input.metaDefaults,
          tiktokDefaults: input.tiktokDefaults,
          youtubeDefaults: input.youtubeDefaults,
          timeframe: input.timeframe ?? undefined,
          startDate: input.startDate ?? undefined,
          endDate: input.endDate ?? undefined,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedCreativeReportingReports } = useCreativeReporting();
        const report = getMappedCreativeReportingReports([
          data.value.data.report,
        ])[0];
        this.reports.push(report);
        return { id: report.id, uuid: report.uuid };
      }
      return null;
    },

    async duplicateReport(input: {
      reportId: number;
      clientId: number;
      folderId: number | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { report: CreativeReportingReportResponse };
      }>(`creative-reporting/duplicate`, {
        method: "POST",
        body: {
          clientId: input.clientId,
          reportId: input.reportId,
          folderId: input.folderId,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedCreativeReportingReports } = useCreativeReporting();
        const report = getMappedCreativeReportingReports([
          data.value.data.report,
        ])[0];
        this.reports.push(report);
        return { id: report.id, uuid: report.uuid };
      }
      return null;
    },

    async updateReportMetadata(input: {
      reportId: number;
      title?: string | undefined | null;
      description?: string | undefined | null;
      selectedViewType?: ViewType | undefined | null;
      colorCodingLayout?: ClientLayout | undefined | null;
      showCreationDateColumn?: boolean | undefined | null;
      showTagColumn?: boolean | undefined | null;
    }) {
      const { error } = await useDatAdsApiFetch(
        `creative-reporting/report-metadata/${input.reportId}`,
        {
          method: "PATCH",
          body: {
            title: input.title === "" ? null : input.title,
            description: input.description === "" ? null : input.description,
            selectedViewType: input.selectedViewType,
            colorCodingLayout: input.colorCodingLayout,
            showCreationDateColumn: input.showCreationDateColumn,
            showTagColumn: input.showTagColumn,
          },
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      const reportIndex = this.reports.findIndex(
        (report) => report.id === input.reportId,
      );
      if (reportIndex !== -1) {
        this.reports.splice(reportIndex, 1, {
          ...this.reports[reportIndex],
          title: input.title ?? this.reports[reportIndex].title,
          description:
            input.description ?? this.reports[reportIndex].description,
          selectedViewType:
            input.selectedViewType ??
            this.reports[reportIndex].selectedViewType,
          colorCodingLayout:
            input.colorCodingLayout ??
            this.reports[reportIndex].colorCodingLayout,
          showCreationDateColumn:
            input.showCreationDateColumn ??
            this.reports[reportIndex].showCreationDateColumn,
          showTagColumn:
            input.showTagColumn ?? this.reports[reportIndex].showTagColumn,
        });
      }
    },

    async updateReport(input: {
      reportId: number;
      timeframe?: Timeframe | undefined | null;
      startDate?: string | undefined | null;
      endDate?: string | undefined | null;
      groupBy?: GroupBy | undefined | null;
      returnProvider: Provider;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          report: CreativeReportingReportResponse;
          customConversionNames: Record<string, string>;
          customMetricRules: CustomMetricRule[];
        };
      }>(`creative-reporting/${input.reportId}`, {
        method: "PATCH",
        body: {
          timeframe: input.timeframe ?? undefined,
          startDate: input.startDate ?? undefined,
          endDate: input.endDate ?? undefined,
          groupBy: input.groupBy ?? undefined,
          returnProvider: input.returnProvider,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.customMetricRules = data.value.data.customMetricRules;
        const { getMappedCreativeReportingReports } = useCreativeReporting();
        const report = getMappedCreativeReportingReports([
          data.value.data.report,
        ])[0];
        this.setReport(report);
        return report;
      }
      return null;
    },

    async createSnapshot(dto: { reportId: number }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          snapshot: ReportSnapshotResponse<CreativeReportingReportResponse>;
        };
      }>("/creative-reporting/snapshot", {
        method: "POST",
        body: { reportId: dto.reportId },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedCreativeReportingReportSnapshpts } =
          useCreativeReporting();
        const snapshot = getMappedCreativeReportingReportSnapshpts([
          data.value.data.snapshot,
        ])[0];
        return snapshot;
      }
      return null;
    },

    async updateView(input: {
      viewId: number;
      pageNumber: number;
      pageSize: number;
      groupBy: GroupBy; // Required for mapping response
      primaryMetric?: string;
      gridMetrics?: Array<string>;
      tableMetrics?: Array<string>;
      filter?: Array<Array<MetricsFilter>> | null;
      sort?: Sort;
      selectedGroupIds?: Array<string> | null;
      selectedCreativeIds?: Array<number> | null;
      leftYAxisMetric?: string | null;
      rightYAxisMetric?: string | null;
      attributionWindow?: AttributionWindow | null;
      sortBy?: SortBy;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { view: CreativeReportingViewResponse };
      }>(`creative-reporting/view/${input.viewId}`, {
        method: "PATCH",
        body: {
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
          primaryMetric: input.primaryMetric ?? undefined,
          gridMetrics: input.gridMetrics ?? undefined,
          tableMetrics: input.tableMetrics ?? undefined,
          filter: input.filter,
          sort: input.sort ?? undefined,
          sortBy: input.sortBy ?? undefined,
          selectedGroupIds: input.selectedGroupIds,
          selectedCreativeIds: input.selectedCreativeIds,
          leftYAxisMetric: input.leftYAxisMetric,
          rightYAxisMetric: input.rightYAxisMetric,
          attributionWindow: input.attributionWindow,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedCreativeReportingViews } = useCreativeReporting();
        const view = getMappedCreativeReportingViews(
          [data.value.data.view],
          input.groupBy,
        )[0];
        const reportIdx = this.reports.findIndex(
          (report) => report.id === view.info.reportId,
        );
        this.setView({
          view,
          reportIdx,
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
        });
        return view;
      }
      return null;
    },

    async getReport(
      input: {
        reportUuid: string;
        provider?: Provider;
        groupBy?: GroupBy | undefined | null;
        resolveAssets?: boolean;
      } & GetOverrides<string>,
    ) {
      const { getOverridesQuery } = useQuery();
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          report: CreativeReportingReportResponse;
          customConversionNames: Record<string, string>;
          colorCodingLayout: ClientLayout;
          targetMetrics: ClientTargetMetric[];
          customMetricRules: CustomMetricRule[];
          currency: string;
        };
      }>(`creative-reporting/report/${input.reportUuid}`, {
        query: {
          ...getOverridesQuery(input),
          provider: input.provider,
          groupBy: input.groupBy,
          resolveAssets: input.resolveAssets,
        },
      });
      if (error.value) {
        const errorMesage = useErrorHandler(error.value);
        return errorMesage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.colorCodingLayout = data.value.data.colorCodingLayout;
        this.targetMetrics = data.value.data.targetMetrics;
        this.customMetricRules = data.value.data.customMetricRules;
        this.currency = data.value.data.currency;
        const { getMappedCreativeReportingReports } = useCreativeReporting();
        const report = getMappedCreativeReportingReports([
          data.value.data.report,
        ])[0];
        this.setReport(report);
        return report;
      }
      return null;
    },

    async getReportSnapshot(dto: { reportUuid: string }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          snapshot: ReportSnapshotResponse<CreativeReportingReportResponse>;
          customConversionNames: Record<string, string>;
          colorCodingLayout: ClientLayout;
          targetMetrics: ClientTargetMetric[];
          customMetricRules: CustomMetricRule[];
          currency: string;
        };
      }>(`creative-reporting/report/${dto.reportUuid}/snapshot`);
      if (error.value) {
        const errorMesage = useErrorHandler(error.value);
        return errorMesage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.colorCodingLayout = data.value.data.colorCodingLayout;
        this.targetMetrics = data.value.data.targetMetrics;
        this.customMetricRules = data.value.data.customMetricRules;
        this.currency = data.value.data.currency;
        const { getMappedCreativeReportingReportSnapshpts } =
          useCreativeReporting();
        const snapshot = getMappedCreativeReportingReportSnapshpts([
          data.value.data.snapshot,
        ])[0];
        return snapshot;
      }
      return null;
    },

    async getView(
      input: {
        viewUuid: string;
        pageNumber: number;
        pageSize: number;
        groupBy: GroupBy;
        resolveAssets: boolean;
      } & GetOverrides<string>,
    ) {
      const { getOverridesQuery } = useQuery();
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          view: CreativeReportingViewResponse;
          customConversionNames: Record<string, string>;
          colorCodingLayout: ClientLayout;
          targetMetrics: ClientTargetMetric[];
          customMetricRules: CustomMetricRule[];
          currency: string;
        };
      }>(`creative-reporting/view/${input.viewUuid}`, {
        query: {
          ...getOverridesQuery(input),
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
          groupBy: input.groupBy,
          resolveAssets: input.resolveAssets,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.colorCodingLayout = data.value.data.colorCodingLayout;
        this.targetMetrics = data.value.data.targetMetrics;
        this.customMetricRules = data.value.data.customMetricRules;
        this.currency = data.value.data.currency;
        const { getMappedCreativeReportingViews } = useCreativeReporting();
        const view = getMappedCreativeReportingViews(
          [data.value.data.view],
          input.groupBy,
        )[0];
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((v) => v.info.uuid === input.viewUuid),
        );
        this.setView({
          view,
          reportIdx,
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
        });
      }
      return null;
    },

    async getGroup(input: GetGroupDto) {
      const { getOverridesQuery } = useQuery();
      const encodedGroupId = encodeURIComponent(
        Buffer.from(input.groupId.toString()).toString("base64"),
      );
      const { data, error } = await useDatAdsApiFetch<{
        data: { group: CreativeReportingGroupResponse };
      }>(`creative-reporting/group`, {
        query: {
          ...getOverridesQuery(input),
          viewUuid: input.viewUuid,
          groupId: encodedGroupId,
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
          groupBy: input.groupBy,
          resolveAssets: input.resolveAssets,
        },
      });
      if (error.value) {
        useErrorHandler(error.value);
        return null;
      }
      if (data.value) {
        const { getMappedCreativeReportingGroups } = useCreativeReporting();
        const group = getMappedCreativeReportingGroups(
          [data.value.data.group],
          input.groupBy,
        )[0];
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((v) => v.info.uuid === input.viewUuid),
        );
        const viewIdx = this.reports[reportIdx].views.findIndex(
          (view) => view.info.uuid === input.viewUuid,
        );
        let groupIdx = this.reports[reportIdx].views[viewIdx].groups.findIndex(
          (g) => g.info.id === group.info.id,
        );
        if (groupIdx === -1) {
          groupIdx = this.reports[reportIdx].views[viewIdx].groups.push(group);
          return this.reports[reportIdx].views[viewIdx].groups[groupIdx];
        }
        if (input.pageNumber === 1) {
          // From the backend we are getting unsorted list of creatives
          // If we are at page 0, we thus need to reset the list.
          // Otherwise we would populate the creatives unsorted
          this.reports[reportIdx].views[viewIdx].groups[groupIdx].creatives =
            [];
        }
        group.creatives.forEach((creative, idx) =>
          this.setCreative({
            creative,
            reportIdx,
            viewIdx,
            groupIdx,
            pageNumber: input.pageNumber,
            pageSize: input.pageSize,
            batchIdx: idx,
          }),
        );
        if (groupIdx !== -1) {
          this.reports[reportIdx].views[viewIdx].groups.splice(groupIdx, 1, {
            ...group,
            // Update all properties except creatives (and derived properties)
            // Otherwise the overall group appearance might change (e.g. images of group)
            info: {
              ...this.reports[reportIdx].views[viewIdx].groups[groupIdx].info,
              images:
                this.reports[reportIdx].views[viewIdx].groups[groupIdx].info
                  .images,
              videos:
                this.reports[reportIdx].views[viewIdx].groups[groupIdx].info
                  .videos,
              image:
                this.reports[reportIdx].views[viewIdx].groups[groupIdx].info
                  .image,
              video:
                this.reports[reportIdx].views[viewIdx].groups[groupIdx].info
                  .video,
            },
            creatives:
              this.reports[reportIdx].views[viewIdx].groups[groupIdx].creatives,
          });
        }
        return this.reports[reportIdx].views[viewIdx].groups[groupIdx];
      }
      return null;
    },

    async listReports(clientId: number) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { reports: CreativeReportingReportResponse[] };
      }>(`creative-reporting/client/${clientId}`);
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedCreativeReportingReports } = useCreativeReporting();
        const reports = getMappedCreativeReportingReports(
          data.value.data.reports,
        );
        this.setReports(reports);
      }
      return this.reports.filter((report) => report.clientId === clientId);
    },

    setReports(reports: CreativeReportingReport[]) {
      // Remove all reports not in returned list
      this.reports = this.reports.filter((report) =>
        reports.some((r) => r.id === report.id),
      );
      // Add or update reports
      reports.forEach((r) => this.setReport(r));
    },

    async listGroups(
      input: {
        viewUuid: string;
        pageNumber: number;
        pageSize: number;
        groupBy: GroupBy;
        resolveAssets: boolean;
      } & GetOverrides<string>,
    ) {
      const { hasFetchedGroups, groupsBatch } = this.getFetchedGroups(input);
      if (hasFetchedGroups) {
        return groupsBatch;
      }
      const { getOverridesQuery } = useQuery();
      const { data, error } = await useDatAdsApiFetch<{
        data: { groups: Array<CreativeReportingGroupResponse> };
      }>(`creative-reporting/group/${input.viewUuid}/list`, {
        query: {
          ...getOverridesQuery(input),
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
          groupBy: input.groupBy,
          resolveAssets: input.resolveAssets,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedCreativeReportingGroups } = useCreativeReporting();
        const groups = getMappedCreativeReportingGroups(
          data.value.data.groups,
          input.groupBy,
        );
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((v) => v.info.uuid === input.viewUuid),
        );
        const viewIdx = this.reports[reportIdx].views.findIndex(
          (view) => view.info.uuid === input.viewUuid,
        );
        groups.forEach((group, idx) =>
          this.setGroup({
            group,
            reportIdx,
            viewIdx,
            pageNumber: input.pageNumber,
            pageSize: input.pageSize,
            batchIdx: idx,
          }),
        );
        return groups;
      }
      return [];
    },

    async listCreativesOfGroup(input: GetGroupDto) {
      const { getOverridesQuery } = useQuery();
      const encodedGroupId = encodeURIComponent(
        Buffer.from(input.groupId.toString()).toString("base64"),
      );
      const { data, error } = await useDatAdsApiFetch<{
        data: { creatives: CreativeResponse[] };
      }>(`creative-reporting/group/list-creatives`, {
        query: {
          ...getOverridesQuery(input),
          viewUuid: input.viewUuid,
          groupId: encodedGroupId,
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
          groupBy: input.groupBy,
        },
      });
      if (error.value) {
        useErrorHandler(error.value);
        return null;
      }
      if (data.value) {
        const { getMappedCreatives } = useCreatives();
        const creatives = getMappedCreatives(data.value.data.creatives);
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((v) => v.info.uuid === input.viewUuid),
        );
        const viewIdx = this.reports[reportIdx].views.findIndex(
          (view) => view.info.uuid === input.viewUuid,
        );
        const groupIdx = this.reports[reportIdx].views[
          viewIdx
        ].groups.findIndex((group) => group.info.id === input.groupId);
        if (input.pageNumber === 1) {
          // From the backend we are getting unsorted list of creatives
          // If we are at page 0, we thus need to reset the list.
          // Otherwise we would populate the creatives unsorted
          this.reports[reportIdx].views[viewIdx].groups[groupIdx].creatives =
            [];
        }
        creatives.forEach((creative, idx) =>
          this.setCreative({
            creative,
            reportIdx,
            viewIdx,
            groupIdx,
            pageNumber: input.pageNumber,
            pageSize: input.pageSize,
            batchIdx: idx,
          }),
        );
        return creatives;
      }
      return [];
    },

    async resolveGroupAssets(dto: {
      groups: CreativeReportingGroup[];
      slice: number | undefined;
      groupBy: GroupBy;
    }) {
      const { resolveGroupAssets } = useAssets();
      const groupsWithAssets = await resolveGroupAssets(dto);
      const { getMappedCreativeReportingGroups } = useCreativeReporting();
      const mappedGroups = getMappedCreativeReportingGroups(
        groupsWithAssets,
        dto.groupBy,
      );
      for (const report of this.reports) {
        for (const view of report.views) {
          view.groups = view.groups.map((group) => {
            const groupWithAssets = mappedGroups.find(
              (g) => g.info.id === group.info.id,
            );
            return groupWithAssets ?? group;
          });
        }
      }
    },

    getFetchedGroups(input: {
      viewUuid: string;
      pageNumber: number;
      pageSize: number;
    }) {
      const reportIdx = this.reports.findIndex((report) =>
        report.views.some((v) => v.info.uuid === input.viewUuid),
      );
      const viewIdx = this.reports[reportIdx].views.findIndex(
        (view) => view.info.uuid === input.viewUuid,
      );
      const { start, end } = getBatchStartEnd({
        pageNumber: input.pageNumber,
        pageSize: input.pageSize,
      });
      const hasEnoughGroups =
        this.reports[reportIdx].views[viewIdx].groups.length >= end;
      if (!hasEnoughGroups) {
        return {
          hasFetchedGroups: false,
          groupsBatch: [],
        };
      }
      const groupsBatch = this.reports[reportIdx].views[viewIdx].groups.slice(
        start,
        end,
      );
      const { allGroupsNotEmpty } = useCreativeReporting();
      return {
        // If the user clicks on a page in the middle of the list,
        // we filled the previous pages with empty groups.
        // If the user then clicks on any page before,
        // the groups might be empty i.e. we have never fetched them even if the list is long enough
        hasFetchedGroups: allGroupsNotEmpty(groupsBatch),
        groupsBatch,
      };
    },

    setReport(report: CreativeReportingReport) {
      const index = this.reports.findIndex((r) => r.id === report.id);
      if (index === -1) {
        this.reports.push(report);
      } else {
        report.views.forEach((view) =>
          this.setView({
            view,
            reportIdx: index,
          }),
        );
        // Keep views
        this.reports.splice(index, 1, {
          ...report,
          views: this.reports[index].views,
        });
      }
    },

    setView(
      dto:
        | {
            view: CreativeReportingView;
            reportIdx: number;
          }
        | {
            view: CreativeReportingView;
            reportIdx: number;
            pageNumber: number;
            pageSize: number;
          },
    ) {
      const views = this.reports[dto.reportIdx].views;
      const index = views.findIndex((v) => v.info.id === dto.view.info.id);
      if (index === -1) {
        views.push(dto.view);
      } else {
        views.splice(index, 1, { ...dto.view });
        if ("pageNumber" in dto && "pageSize" in dto) {
          // Groups might be at incorrect position in array
          // E.g. pagesize 8, pagenumber 2, then 8 groups are fetched, but at indices 0-7
          views[index].groups = [];
          dto.view.groups.forEach((group, idx) =>
            this.setGroup({
              group,
              reportIdx: dto.reportIdx,
              viewIdx: index,
              pageNumber: dto.pageNumber,
              pageSize: dto.pageSize,
              batchIdx: idx,
            }),
          );
        }
      }
    },

    setGroup(input: {
      group: CreativeReportingGroup;
      reportIdx: number;
      viewIdx: number;
      pageNumber: number;
      pageSize: number;
      batchIdx: number; // Index of group in batch
    }) {
      const { getEmptyGroup, isEmptyGroup } = useCreativeReporting();

      const groups = this.reports[input.reportIdx].views[input.viewIdx].groups;
      const { start, end } = getBatchStartEnd({
        pageNumber: input.pageNumber,
        pageSize: input.pageSize,
      });
      const groupsBatch = groups.slice(start, end);

      // If the user clicks on a page in the middle of the list
      // we need to fill the previous pages with empty groups
      // Those get replaced by the actual groups when we fetch them
      if (groups.length < start) {
        for (let i = groups.length; i < start; i++) {
          groups.push(
            getEmptyGroup({
              idx: i,
              groupBy: input.group.info.groupBy,
              reportId: input.group.info.reportId,
              viewId: input.group.info.viewId,
            }),
          );
        }
      }

      // Replace empty group with actual group
      if (
        groupsBatch[input.batchIdx] != null &&
        isEmptyGroup(groupsBatch[input.batchIdx])
      ) {
        groups.splice(start + input.batchIdx, 1, input.group);
        return;
      }

      // Add group to groups
      groups.splice(start + input.batchIdx, 0, input.group);
    },

    setCreative(input: {
      creative: Creative;
      reportIdx: number;
      viewIdx: number;
      groupIdx: number;
      pageNumber: number;
      pageSize: number;
      batchIdx: number; // Index of creative in batch
    }) {
      const { getEmptyCreative, isEmptyCreative } = useCreatives();

      const creatives =
        this.reports[input.reportIdx].views[input.viewIdx].groups[
          input.groupIdx
        ].creatives;
      const { start, end } = getBatchStartEnd({
        pageNumber: input.pageNumber,
        pageSize: input.pageSize,
      });
      const creativesBatch = creatives.slice(start, end);

      // If the user clicks on a page in the middle of the list
      // we need to fill the previous pages with empty creatives
      // Those get replaced by the actual creatives when we fetch them
      if (creatives.length < start) {
        for (let i = creatives.length; i < start; i++) {
          creatives.push(
            getEmptyCreative({
              idx: i,
            }),
          );
        }
      }

      // Replace empty creative with actual creative
      if (
        creativesBatch[input.batchIdx] != null &&
        isEmptyCreative(creativesBatch[input.batchIdx])
      ) {
        creatives.splice(start + input.batchIdx, 1, input.creative);
        return;
      }

      // Add creative to creatives
      creatives.splice(start + input.batchIdx, 0, input.creative);
    },

    async deleteReport(reportId: number) {
      const { error } = await useDatAdsApiFetch(
        `creative-reporting/${reportId}`,
        {
          method: "DELETE",
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      const index = this.reports.findIndex((report) => report.id === reportId);
      if (index !== -1) {
        this.reports.splice(index, 1);
      }
      return null;
    },

    async listAds(
      input: {
        clientId: number;
        pageNumber: number;
        pageSize: number;
      } & Partial<{
        creativeId: string;
        sort: Sort;
        orderBy: "id" | "create_date";
        labeled: boolean;
        provider: Provider;
        filter: MetricsFilter[][];
        timeframe: Timeframe;
        startDate: string;
        endDate: string;
      }>,
    ) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          ads: Array<CreativeInfoResponse>;
          pageNumber: number;
          pageSize: number;
          total: number;
        };
      }>(`creative-reporting/client/${input.clientId}/ads`, {
        query: {
          pageSize: input.pageSize,
          pageNumber: input.pageNumber,
          provider: input.provider,
          creativeId: input.creativeId,
          sort: input.sort,
          orderBy: input.orderBy,
          labeled: input.labeled,
          filter: Array.isArray(input.filter)
            ? JSON.stringify(input.filter)
            : undefined,
          timeframe: input.timeframe ?? undefined,
          startDate: input.startDate ?? undefined,
          endDate: input.endDate ?? undefined,
        },
      });
      if (error.value) {
        useErrorHandler(error.value);
        return {
          ads: [],
          pageNumber: 1,
          pageSize: 1,
          total: 0,
        };
      }
      if (data.value) {
        const { getMappedAdInfo } = useCreatives();
        const ads = getMappedAdInfo(data.value.data.ads);
        for (const ad of ads) {
          if (this.ads.some((a) => a.id === ad.id)) {
            const index = this.ads.findIndex((a) => a.id === ad.id);
            this.ads.splice(index, 1, ad);
          } else {
            this.ads.push(ad);
          }
        }
        return {
          ads,
          pageNumber: data.value.data.pageNumber,
          pageSize: data.value.data.pageSize,
          total: data.value.data.total,
        };
      }
      return {
        ads: [],
        pageNumber: 1,
        pageSize: 1,
        total: 0,
      };
    },

    async getAssetsOfAds(providerIds: string[]) {
      if (providerIds.length === 0) {
        return [];
      }
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          assets: Array<AssetInfo>;
        };
      }>(`creative-reporting/assets`, {
        query: {
          providerIds: JSON.stringify(providerIds),
        },
      });
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        for (const asset of data.value.data.assets) {
          if (this.assets.some((a) => a.providerId === asset.providerId)) {
            const index = this.assets.findIndex(
              (a) => a.providerId === asset.providerId,
            );
            this.assets.splice(index, 1, asset);
          } else {
            this.assets.push(asset);
          }
        }
      }
      return this.assets;
    },

    purgeAds() {
      this.ads = [];
    },

    markAllAdsAsLabeled() {
      this.ads = this.ads.map((ad) => ({
        ...ad,
        isLabeled: true,
      }));
    },

    assignTagToAd(dto: { adId: number; tag: AdGroupAdTag }) {
      this.ads = this.ads.map((ad) => {
        const idx = ad.tags.findIndex((tag) => tag.id === dto.tag.id);
        if (ad.id === dto.adId && idx === -1) {
          ad.tags.push(dto.tag);
        }
        return ad;
      });
      this.creativeInfos = this.creativeInfos.map((creative) => {
        const idx = creative.tags.findIndex((tag) => tag.id === dto.tag.id);
        if (creative.id === dto.adId && idx === -1) {
          creative.tags.push(dto.tag);
        }
        return creative;
      });
      this.reports = this.reports.map((report) => {
        report.views = report.views.map((view) => {
          view.groups = view.groups.map((group) => {
            group.creatives = group.creatives.map((creative) => {
              const idx = creative.info.tags.findIndex(
                (tag) => tag.id === dto.tag.id,
              );
              if (creative.info.id === dto.adId && idx === -1) {
                creative.info.tags.push(dto.tag);
              }
              return creative;
            });
            return group;
          });
          return view;
        });
        return report;
      });
    },

    removeTagFromAd(dto: { adId: number; tagId: number }) {
      this.ads = this.ads.map((ad) => {
        const idx = ad.tags.findIndex((tag) => tag.id === dto.tagId);
        if (ad.id === dto.adId && idx !== -1) {
          ad.tags.splice(idx, 1);
        }
        return ad;
      });
      this.creativeInfos = this.creativeInfos.map((creative) => {
        const idx = creative.tags.findIndex((tag) => tag.id === dto.tagId);
        if (creative.id === dto.adId && idx !== -1) {
          creative.tags.splice(idx, 1);
        }
        return creative;
      });
      this.reports = this.reports.map((report) => {
        report.views = report.views.map((view) => {
          view.groups = view.groups.map((group) => {
            group.creatives = group.creatives.map((creative) => {
              const idx = creative.info.tags.findIndex(
                (tag) => tag.id === dto.tagId,
              );
              if (creative.info.id === dto.adId && idx !== -1) {
                creative.info.tags.splice(idx, 1);
              }
              return creative;
            });
            return group;
          });
          return view;
        });
        return report;
      });
    },

    removeTagFromCreative(dto: { creativeId: string; tagId: number }) {
      this.ads = this.ads.map((ad) => {
        const idx = ad.tags.findIndex((tag) => tag.id === dto.tagId);
        if (ad.creativeId === dto.creativeId && idx !== -1) {
          ad.tags.splice(idx, 1);
        }
        return ad;
      });
      this.creativeInfos = this.creativeInfos.map((creative) => {
        const idx = creative.tags.findIndex((tag) => tag.id === dto.tagId);
        if (creative.creativeId === dto.creativeId && idx !== -1) {
          creative.tags.splice(idx, 1);
        }
        return creative;
      });
      this.reports = this.reports.map((report) => {
        report.views = report.views.map((view) => {
          view.groups = view.groups.map((group) => {
            group.creatives = group.creatives.map((creative) => {
              const idx = creative.info.tags.findIndex(
                (tag) => tag.id === dto.tagId,
              );
              if (creative.info.creativeId === dto.creativeId && idx !== -1) {
                creative.info.tags.splice(idx, 1);
              }
              return creative;
            });
            return group;
          });
          return view;
        });
        return report;
      });
    },

    updateTagAcrossAds(dto: { tag: AdGroupAdTag }) {
      this.ads = this.ads.map((ad) => {
        const idx = ad.tags.findIndex((tag) => tag.id === dto.tag.id);
        if (idx !== -1) {
          ad.tags.splice(idx, 1, dto.tag);
        }
        return ad;
      });
    },

    removeTagAcrossAds(dto: { tagId: number }) {
      this.ads = this.ads.map((ad) => {
        const idx = ad.tags.findIndex((tag) => tag.id === dto.tagId);
        if (idx !== -1) {
          ad.tags.splice(idx, 1);
        }
        return ad;
      });
    },

    async listCreatives(input: {
      clientId: number;
      pageSize: number;
      pageNumber: number;
      provider: Provider;
      primaryMetric: string;
      filter: MetricsFilter[][];
      timeframe: Timeframe | null;
      startDate: string | null;
      endDate: string | null;
      sortBy: SortBy | null;
    }) {
      if (input.pageNumber === 1) {
        this.creatives[input.clientId] = {
          creatives: [],
          total: 0,
        };
      }
      const { data, error } = await useDatAdsApiFetch<{
        data: { creatives: Array<CreativeResponse>; total: number };
      }>(`creative-reporting/creatives/client/${input.clientId}`, {
        query: {
          pageSize: input.pageSize,
          pageNumber: input.pageNumber,
          provider: input.provider,
          primaryMetric: input.primaryMetric,
          filter: JSON.stringify(input.filter),
          timeframe: input.timeframe ?? undefined,
          startDate: input.startDate ?? undefined,
          endDate: input.endDate ?? undefined,
          sortBy: input.sortBy ?? undefined,
        },
      });
      if (error.value) {
        useErrorHandler(error.value);
        return {
          creatives: [],
          total: 0,
        };
      }
      if (data.value) {
        const { getMappedCreatives } = useCreatives();
        const creatives = getMappedCreatives(data.value.data.creatives);
        const oldCreatives = this.creatives[input.clientId]
          ? this.creatives[input.clientId].creatives
          : [];
        this.creatives[input.clientId] = {
          creatives: [...oldCreatives],
          total: data.value.data.total,
        };
        creatives.forEach((creative, idx) =>
          this.setClientCreative({
            creative,
            clientId: input.clientId,
            pageNumber: input.pageNumber,
            pageSize: input.pageSize,
            batchIdx: idx,
          }),
        );
      }
      return this.creatives;
    },

    async getCreative(providerId: string) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          creative: CreativeInfoResponse;
          campaign: AdCampaignInfo | null;
          adGroup: AdGroupInfo | null;
        };
      }>(`creative-reporting/creative/${providerId}`);
      if (error.value) {
        useErrorHandler(error.value);
        return null;
      }
      if (data.value) {
        const { getMappedCreativeInfo } = useCreatives();
        const creative = getMappedCreativeInfo([data.value.data.creative])[0];
        if (this.creativeInfos.some((c) => c.id === creative.id)) {
          const index = this.creativeInfos.findIndex(
            (c) => c.id === creative.id,
          );
          this.creativeInfos.splice(index, 1, creative);
        } else {
          this.creativeInfos.push(creative);
        }
        if (data.value.data.campaign) {
          this.creativeIdToCampaignInfo[creative.id] = data.value.data.campaign;
        }
        if (data.value.data.adGroup) {
          this.creativeIdToAdGroupInfo[creative.id] = data.value.data.adGroup;
        }
        return creative;
      }
      return null;
    },

    resetCreatives(clientId: number) {
      this.creatives[clientId] = {
        creatives: [],
        total: 0,
      };
    },

    setClientCreative(input: {
      creative: Creative;
      clientId: number;
      pageNumber: number;
      pageSize: number;
      batchIdx: number; // Index of creative in batch
    }) {
      const { getEmptyCreative, isEmptyCreative } = useCreatives();

      const creatives = this.creatives[input.clientId].creatives;
      const { start, end } = getBatchStartEnd({
        pageNumber: input.pageNumber,
        pageSize: input.pageSize,
      });
      const creativesBatch = creatives.slice(start, end);

      // If the user clicks on a page in the middle of the list
      // we need to fill the previous pages with empty creatives
      // Those get replaced by the actual creatives when we fetch them
      if (creatives.length < start) {
        for (let i = creatives.length; i < start; i++) {
          creatives.push(
            getEmptyCreative({
              idx: i,
            }),
          );
        }
      }

      // Replace empty creative with actual creative
      if (
        creativesBatch[input.batchIdx] != null &&
        isEmptyCreative(creativesBatch[input.batchIdx])
      ) {
        creatives.splice(start + input.batchIdx, 1, input.creative);
        return;
      }

      // Add creative to creatives
      creatives.splice(start + input.batchIdx, 0, input.creative);
    },

    addCustomMetricRule(rule: CustomMetricRule) {
      this.customMetricRules.push(rule);
    },

    async updateCustomMetricRule(dto: {
      newRule: CustomMetricRule;
      oldRule: CustomMetricRule;
      viewId: number;
    }) {
      const { newRule, oldRule, viewId } = dto;
      const index = this.customMetricRules.findIndex(
        (r) => r.customMetricRuleId === newRule.customMetricRuleId,
      );
      if (index < 0) {
        return;
      }
      this.customMetricRules.splice(index, 1, { ...newRule });
      const isRenamed = newRule.metricName !== oldRule.metricName;
      if (!isRenamed) {
        return;
      }
      await this.renameCustomMetricRule({
        newName: newRule.metricName,
        oldName: oldRule.metricName,
        viewId,
      });
    },

    async renameCustomMetricRule(dto: {
      newName: string;
      oldName: string;
      viewId: number;
    }) {
      const { newName, oldName, viewId } = dto;
      this.customMetricRules.forEach((rule) => {
        if (rule.metricName === oldName) {
          rule.metricName = newName;
        }
      });
      const view = this.reports
        .flatMap((report) => report.views)
        .find((view) => view.info.id === viewId);
      if (view == null) {
        return;
      }
      const { updateQuery } = useQuery();
      if (view.info.primaryMetric === oldName) {
        view.info.primaryMetric = newName;
        await updateQuery({ primaryMetric: newName });
      }
      if (view.info.leftYAxisMetric === oldName) {
        view.info.leftYAxisMetric = newName;
        await updateQuery({ leftYAxisMetric: newName });
      }
      if (view.info.rightYAxisMetric === oldName) {
        view.info.rightYAxisMetric = newName;
        await updateQuery({ rightYAxisMetric: newName });
      }
      view.info.gridMetrics = view.info.gridMetrics.map((m) =>
        m === oldName ? newName : m,
      );
      await updateQuery({ gridMetrics: JSON.stringify(view.info.gridMetrics) });
      view.info.tableMetrics = view.info.tableMetrics.map((m) =>
        m === oldName ? newName : m,
      );
      await updateQuery({
        tableMetrics: JSON.stringify(view.info.tableMetrics),
      });
      if (view.info.filter != null) {
        const { renameFiltersWithCustomMetric } = useFilter();
        view.info.filter = renameFiltersWithCustomMetric({
          oldMetricName: oldName,
          newMetricName: newName,
          filter: view.info.filter,
        });
        await updateQuery({ filters: JSON.stringify(view.info.filter) });
      }
    },

    async deleteCustomMetricRule(ruleId: number, viewId: number) {
      const index = this.customMetricRules.findIndex(
        (r) => r.customMetricRuleId === ruleId,
      );
      if (index < 0) {
        return;
      }
      const rule = { ...this.customMetricRules[index] };
      this.customMetricRules.splice(index, 1);
      const view = this.reports
        .flatMap((report) => report.views)
        .find((view) => view.info.id === viewId);
      if (view == null) {
        return;
      }
      const { updateQuery } = useQuery();
      const gridIdx = view.info.gridMetrics.findIndex(
        (m) => m === rule.metricName,
      );
      if (gridIdx >= 0) {
        view.info.gridMetrics.splice(gridIdx, 1);
        await updateQuery({
          gridMetrics: JSON.stringify(view.info.gridMetrics),
        });
      }
      const tableIdx = view.info.tableMetrics.findIndex(
        (m) => m === rule.metricName,
      );
      if (tableIdx >= 0) {
        view.info.tableMetrics.splice(tableIdx, 1);
        await updateQuery({
          tableMetrics: JSON.stringify(view.info.tableMetrics),
        });
      }
      if (view.info.primaryMetric === rule.metricName) {
        const { getProviderDefaultMetric } = useMetrics();
        view.info.primaryMetric = getProviderDefaultMetric(view.info.provider);
        await updateQuery({ primaryMetric: view.info.primaryMetric });
      }
      if (view.info.leftYAxisMetric === rule.metricName) {
        view.info.leftYAxisMetric = null;
        await updateQuery({ leftYAxisMetric: null });
      }
      if (view.info.rightYAxisMetric === rule.metricName) {
        view.info.rightYAxisMetric = null;
        await updateQuery({ rightYAxisMetric: null });
      }
      if (view.info.filter != null) {
        const { removeFiltersWithCustomMetric } = useFilter();
        view.info.filter = removeFiltersWithCustomMetric({
          metricName: rule.metricName,
          filter: view.info.filter,
        });
        await updateQuery({ filters: JSON.stringify(view.info.filter) });
      }
    },
  },
  getters: {
    getReportsOfClient: (state) => (clientId: number) => {
      return state.reports.filter((report) => report.clientId === clientId);
    },

    getReportById: (state) => (reportId: number) => {
      return state.reports.find((report) => report.id === reportId) ?? null;
    },

    getReportByUuid: (state) => (reportUuid: string) => {
      return state.reports.find((report) => report.uuid === reportUuid) ?? null;
    },

    ownCustomMetricRules: (state) => {
      return state.customMetricRules.filter(
        (rule) =>
          rule.customMetricRuleId != null && rule.customMetricRuleId > 0,
      );
    },

    isEnrichingCustomMetricRule: (state) => (metricName: string) => {
      return state.customMetricRules.some(
        (rule) =>
          rule.metricName === metricName && rule.customMetricRuleId == null,
      );
    },

    getUnlabeledAds: (state) => () => {
      return state.ads.filter((ad) => !ad.isLabeled);
    },

    getLabeledAds: (state) => () => {
      return state.ads.filter((ad) => ad.isLabeled);
    },
  },
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCreativeReportingStore, import.meta.hot),
  );
}
