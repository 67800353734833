import { arraysEqual } from "~/shared/utils";
import { CreativeInfo } from "~/types/Creative";
import { CreativeComparisonGroup } from "~/types/CreativeComparison";
import { CreativeReportingGroup } from "~/types/CreativeReporting";
import { GroupBy } from "~/types/shared";

export const useAssets = () => {
  const resolveGroupAssets = async <
    T extends CreativeReportingGroup | CreativeComparisonGroup,
  >(dto: {
    groups: T[];
    slice: number | undefined;
    groupBy: GroupBy;
  }) => {
    if (dto.groups.length <= 0) {
      return [];
    }
    const ads = dto.groups
      .flatMap((g) => g.creatives.slice(0, dto.slice))
      .map((c) => c.info);
    const adsWithAssets = await resolveAdGroupAdAssets(ads);
    const { getMappedCreatives } = useCreatives();
    return dto.groups
      .map((g) => {
        return {
          ...g,
          creatives: g.creatives.map((c) => {
            const info = c.info;
            if (dto.groupBy === GroupBy.DYNAMIC_CREATIVE) {
              // Since ad_group_ad_creatives may belong to multiple ads, we can't match by id as this is not unique
              const adMatchedByImages = adsWithAssets.find((a) =>
                arraysEqual(a.imageIds, info.imageIds),
              );
              const adMatchedByVideos = adsWithAssets.find((a) =>
                arraysEqual(a.videoIds, info.videoIds),
              );
              const images = adMatchedByImages
                ? info.imageIds
                    .flatMap((id) => adMatchedByImages.imageIdToUrls[id])
                    .filter((i) => i)
                : c.info.images;
              const videos = adMatchedByVideos
                ? info.videoIds
                    .flatMap((id) => adMatchedByVideos.videoIdToUrls[id])
                    .filter((v) => v)
                : c.info.videos;
              return {
                ...c,
                info: {
                  ...info,
                  images,
                  videos,
                },
              };
            }
            const ad = adsWithAssets.find((a) => a.id === info.id);
            if (!ad) {
              return c;
            }
            return {
              ...c,
              info: {
                ...info,
                images: [...ad.images],
                videos: [...ad.videos],
              },
            };
          }),
        };
      })
      .map((g) => {
        return {
          ...g,
          creatives: getMappedCreatives(g.creatives),
        };
      });
  };

  const resolveAdGroupAdAssets = async <T extends CreativeInfo>(
    ads: T[],
  ): Promise<T[]> => {
    if (ads.length <= 0) {
      return [];
    }
    const creativeReportingStore = useCreativeReportingStore();
    const unresolvedAds = ads.filter(
      (ad) =>
        !creativeReportingStore.assets.find(
          (a) => a.providerId === ad.providerId,
        ),
    );
    const providerIds = unresolvedAds.map((ad) => ad.providerId);
    await creativeReportingStore.getAssetsOfAds(providerIds);
    return ads.map((ad) => {
      const assets = creativeReportingStore.assets.find(
        (a) => a.providerId === ad.providerId,
      );
      return {
        ...ad,
        images: assets?.images ?? ad.images,
        videos: assets?.videos ?? ad.videos,
        imageIdToUrls: assets?.imageIdToUrls ?? ad.imageIdToUrls,
        videoIdToUrls: assets?.videoIdToUrls ?? ad.videoIdToUrls,
      };
    });
  };

  return {
    resolveGroupAssets,
    resolveAdGroupAdAssets,
  };
};
